import React from 'react';
import { Link } from "react-router-dom";
import Logo from "./myLogo.svg";

function Navbar(props) {
    return (
        <div>
          
        <div className = "navBar_outer"> 
            <Link to = "/">
                <img src = {Logo} alt = "logo" className = "logoItem"/>
            </Link>
            <a className = "navBar_item" href = "mailto:ajwitt211@gmail.com">contact</a>
            <a className = "navBar_item" href = "./Resume - Aidan Wittenberg.pdf">resume</a>
            {/* <Link className = "navBar_item" to = "/Projects">projects</Link>  */}
        </div>
        </div>
    )
}

export default Navbar;