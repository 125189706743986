import React from 'react';

function Projects (props) {
    return (
        <div>
            <p className = "explanationHeader">
            Work in Progress :)
            </p>
        </div>
    )
}

export default Projects;