import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navbar, Home, Projects } from "./components";

function App (props) {
    return (
        <div>
            <Router>
                <Navbar />
                <Switch>
                    <Route path="/" exact component={() => <Home />} />
                    <Route path="/Projects" exact component={() => <Projects />} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;