import React from 'react'; 
import ProfilePicture from "./Headshot_Circle.png";

function SelfCard(props) {
    return (
        <div id = "rightItems">
            <div className = "selfCard"> 
                <img src = {ProfilePicture} alt = "ProfilePicture" id = "profilePicture"/>
                <h1 className = "myName">Aidan Wittenberg</h1>
                <hr className = "divider"/>
                <p className = "jobTitle">Software Developer</p>
                <p className = "school">UCLA 2024</p>


                <div className = "selfCard_bottom">
                    
                    <div id = "linksList">
                        <div className = "linksList_item"> 
                            <a href = "https://www.linkedin.com/in/aidanwittenberg/"> 
                                <svg className = "linksList_item_style" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 1920">
                                    <g>
                                        <path d="M437.8,242c0,112.7-83.2,204-220.1,204C88.8,446,5.6,354.7,5.6,242c0-115.4,85.9-204,217.4-204
                                            C354.6,37.9,435.1,126.5,437.8,242z M16.3,1920V607.1h408.1V1920H16.3z"/>
                                        <path d="M668.6,1025.9c0-163.8-5.4-300.7-10.7-418.8h354.4l18.8,182.6h8c53.7-85.9,185.3-212.1,405.4-212.1
                                            c268.5,0,469.8,179.9,469.8,566.5V1920h-408.1v-727.6c0-169.1-59.1-284.6-206.8-284.6c-112.8,0-179.9,77.9-209.4,153
                                            c-10.7,26.8-13.5,64.4-13.5,102V1920H668.6V1025.9z"/>
                                    </g>
                                </svg>
                            </a> 
                        </div>
                                
                        <div className = "linksList_item"> 
                            <a href = "https://github.com/ajwitt212">
                                    <svg className = "linksList_item_style" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 1920">
                                    <path d="M1791.2,525.1c-85.8-147.1-202.3-263.5-349.4-349.4C1294.8,89.9,1134.2,47,960,47
                                    c-174.2,0-334.8,42.9-481.9,128.8C331,261.6,214.6,378,128.8,525.1C42.9,672.2,0,832.8,0,1006.9c0,209.2,61,397.3,183.1,564.4
                                    c122.1,167.1,279.8,282.7,473.1,346.9c22.5,4.2,39.2,1.2,50-8.7c10.8-10,16.2-22.5,16.2-37.5c0-2.5-0.2-25-0.6-67.5
                                    c-0.4-42.5-0.6-79.6-0.6-111.2l-28.8,5c-18.3,3.4-41.5,4.8-69.4,4.4c-27.9-0.4-56.9-3.3-86.9-8.8c-30-5.4-57.9-17.9-83.8-37.5
                                    c-25.8-19.6-44.2-45.2-55-76.9l-12.5-28.8c-8.3-19.1-21.4-40.4-39.4-63.7c-17.9-23.3-36-39.2-54.4-47.5l-8.8-6.3
                                    c-5.8-4.2-11.2-9.2-16.2-15c-5-5.8-8.7-11.7-11.2-17.5c-2.5-5.8-0.4-10.6,6.2-14.4c6.7-3.8,18.7-5.6,36.3-5.6l25,3.7
                                    c16.7,3.3,37.3,13.3,61.9,30c24.6,16.7,44.8,38.3,60.6,65c19.2,34.2,42.3,60.2,69.4,78.1c27.1,17.9,54.4,26.9,81.9,26.9
                                    c27.5,0,51.2-2.1,71.2-6.2c20-4.2,38.7-10.4,56.2-18.8c7.5-55.9,27.9-98.8,61.2-128.8c-47.5-5-90.2-12.5-128.1-22.5
                                    c-37.9-10-77.1-26.3-117.5-48.8c-40.4-22.5-74-50.4-100.6-83.7c-26.7-33.3-48.5-77.1-65.6-131.3c-17.1-54.2-25.6-116.7-25.6-187.5
                                    c0-100.8,32.9-186.7,98.8-257.5c-30.8-75.8-27.9-160.8,8.7-255c24.2-7.5,60-1.9,107.5,16.9c47.5,18.8,82.3,34.8,104.4,48.1
                                    c22.1,13.3,39.8,24.6,53.1,33.7c77.5-21.7,157.5-32.5,240-32.5s162.5,10.8,240,32.5l47.5-30c32.5-20,70.8-38.3,115-55
                                    c44.2-16.7,77.9-21.2,101.3-13.7c37.5,94.2,40.8,179.2,10,255c65.8,70.8,98.8,156.7,98.8,257.5c0,70.8-8.6,133.5-25.6,188.1
                                    c-17.1,54.6-39.1,98.3-66.2,131.3c-27.1,32.9-60.9,60.6-101.3,83.1c-40.4,22.5-79.6,38.7-117.5,48.8c-37.9,10-80.6,17.5-128.1,22.5
                                    c43.3,37.5,65,96.7,65,177.5v263.7c0,15,5.2,27.5,15.6,37.5c10.4,10,26.9,12.9,49.4,8.7c193.4-64.2,351.1-179.8,473.1-346.9
                                    c122.1-167.1,183.1-355.2,183.1-564.4C1920,832.8,1877,672.2,1791.2,525.1z"/>
                                </svg>
                            </a>
                        </div>

                        <div className = "linksList_item"> 
                            <a href = "https://www.instagram.com/ajwitt212/">
                                <svg className = "linksList_item_style" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 1920">
                                    <path d="M960,173c256.3,0,286.7,1,387.9,5.6c93.6,4.3,144.4,19.9,178.3,33.1c44.8,17.4,76.8,38.2,110.4,71.8
                                    c33.6,33.6,54.4,65.6,71.8,110.4c13.1,33.8,28.8,84.7,33.1,178.3c4.6,101.2,5.6,131.6,5.6,387.9s-1,286.7-5.6,387.9
                                    c-4.3,93.6-19.9,144.4-33.1,178.3c-17.4,44.8-38.2,76.8-71.8,110.4c-33.6,33.6-65.6,54.4-110.4,71.8
                                    c-33.8,13.1-84.7,28.8-178.3,33.1c-101.2,4.6-131.6,5.6-387.9,5.6s-286.7-1-387.9-5.6c-93.6-4.3-144.4-19.9-178.3-33.1
                                    c-44.8-17.4-76.8-38.2-110.4-71.8s-54.4-65.6-71.8-110.4c-13.1-33.8-28.8-84.7-33.1-178.3c-4.6-101.2-5.6-131.6-5.6-387.9
                                    s1-286.7,5.6-387.9c4.3-93.6,19.9-144.4,33.1-178.3C229,349,249.8,317,283.4,283.4S349,229,393.8,211.6
                                    c33.8-13.1,84.7-28.8,178.3-33.1C673.3,174,703.7,173,960,173 M960,0C699.3,0,666.6,1.1,564.2,5.8c-102.2,4.7-172,20.9-233,44.6
                                    c-63.1,24.5-116.7,57.4-170,110.7S74.9,268,50.4,331.2c-23.7,61.1-40,130.8-44.6,233C1.1,666.6,0,699.3,0,960s1.1,293.4,5.8,395.8
                                    c4.7,102.2,20.9,172,44.6,233c24.5,63.1,57.4,116.7,110.7,170s106.9,86.2,170,110.7c61.1,23.7,130.8,40,233,44.6
                                    c102.4,4.7,135.1,5.8,395.8,5.8s293.4-1.1,395.8-5.8c102.2-4.7,172-20.9,233-44.6c63.1-24.5,116.7-57.4,170-110.7
                                    s86.2-106.9,110.7-170c23.7-61.1,40-130.8,44.6-233c4.7-102.4,5.8-135.1,5.8-395.8s-1.1-293.4-5.8-395.8
                                    c-4.7-102.2-20.9-172-44.6-233c-24.5-63.1-57.4-116.7-110.7-170s-106.9-86.2-170-110.7c-61.1-23.7-130.8-40-233-44.6
                                    C1253.4,1.1,1220.7,0,960,0z M960,467c-272.3,0-493,220.7-493,493s220.7,493,493,493s493-220.7,493-493S1232.3,467,960,467z
                                    M960,1280c-176.7,0-320-143.3-320-320s143.3-320,320-320s320,143.3,320,320S1136.7,1280,960,1280z M1587.6,447.6
                                    c0,63.6-51.6,115.2-115.2,115.2c-63.6,0-115.2-51.6-115.2-115.2s51.6-115.2,115.2-115.2C1536.1,332.4,1587.6,383.9,1587.6,447.6z"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div className = "linksList_item"> 
                            <a href = "https://twitter.com/ajwitt212">
                                <svg className = "linksList_item_style" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 1920">
                                    <path d="M1920,364.6c-70.6,31.4-146.6,52.5-226.2,62c81.4-48.7,143.8-125.9,173.2-217.9
                                    c-76.1,45.1-160.4,77.9-250.2,95.6c-71.8-76.6-174.2-124.4-287.5-124.4c-254.3,0-441.2,237.3-383.8,483.6
                                    C618.2,647,328,490.2,133.7,251.9C30.5,429,80.2,660.6,255.5,777.8c-64.5-2.1-125.3-19.8-178.3-49.3
                                    c-4.3,182.5,126.5,353.2,315.9,391.2c-55.4,15-116.2,18.6-177.9,6.7c50.1,156.5,195.5,270.3,368,273.5
                                    C417.6,1529.8,209,1587.8,0,1563.2c174.3,111.8,381.4,177,603.8,177c731.4,0,1144.6-617.7,1119.6-1171.7
                                    C1800.4,512.9,1867.2,443.5,1920,364.6z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

function Website(props) {
    return (
        <div className = "websiteLayout">          
            <div id = "leftItems">
                <div className = "explanation">

                    <div className = "colorBox"></div>

                    <div className = "explanationHeader"> 
                    &#123; <i> Motivated<br/>& Inspired</i>&ensp;&#125;
                    </div>
                    
                    <a className = "button" href = "./Resume - Aidan Wittenberg.pdf">resume</a>
                    {/* <Link className = "button" to = "/Projects">projects</Link> */}

                    <div className = "explanationParagraph">
                        Hi there, I'm Aidan! <br/>
                        I'm looking forward to using my skills to make a positive, <br/>
                        tangible, and impactful change in the world around me.
                    </div>
                </div>
            </div>
        
            <div id = "rightItems">
                <SelfCard/>
            </div>
        </div>
    )
}

export default Website;